@use '@angular/material' as mat;
@mixin portal-tabbed-charts-widget-theme($theme) {
  $red: mat.m2-define-palette(mat.$m2-red-palette);
  $green: mat.m2-define-palette(mat.$m2-green-palette);

  .portal-stat-tab {
    .portal-trend-down {
      color: mat.m2-get-color-from-palette($red, default);
    }

    .portal-trend-up {
      color: mat.m2-get-color-from-palette($green, default);
    }
  }
}
