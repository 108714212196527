@use '@angular/material' as mat;
@use 'color-palette';
@use "sass:map";

@mixin portal-inbox($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .portal-inbox-app__wrapper {
    background: mat.m2-get-color-from-palette($background, background);
  }

  .portal-inbox-app__sidenav {
    background: mat.m2-get-color-from-palette($background, card);
    overflow-y: auto;
    z-index: 2;
    min-width: 300px;
  }

  .portal-inbox-app__list-item {
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);

    .mat-icon {
      opacity: 0.5;
      transition: color 0.5s, opacity 0.5s;
      text-align: center;
    }

    &.portal-inbox-app__list-item--active {
      background-color: var(--c-gray-85);
      .mat-icon {
        color: mat.m2-get-color-from-palette($accent, default);
        opacity: 1;
        animation: portal-inbox-app__list-item 0.5s ease-out;
        animation-iteration-count: 1;
      }
    }
  }
  .portal-messages-container__new-message-wrapper {
    background: mat.m2-get-color-from-palette($background, card);
    padding: 0 12px;

    .mat-icon {
      font-size: 18px;
    }
  }

  .portal-inbox-no-messages {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    .mat-icon {
      color: mat.m2-get-color-from-palette($primary, default);

      &:nth-child(2) {
        color: mat.m2-get-color-from-palette($accent, default);
      }
    }
  }

  .portal-inbox-no-messages-dots span {
    background: mat.m2-get-color-from-palette($accent, default-contrast);
  }

  .portal-inbox-no-messages-icon {
    .mat-icon {
      font-size: 4rem;
      height: 4rem;
      width: 6rem;
      line-height: 4rem !important;
    }
  }

  .no-new-evictions {
    text-align: center;
  }

  // Demo animations
  @keyframes portal-inbox-no-messages-icon {
    0%,
    50% {
      transform: translateY(15px);
      opacity: 0;
    }
    50%,
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes portal-inbox-no-messages-dots {
    0%,
    60%,
    100% {
      transform: translateY(0px);
      opacity: 1;
    }

    30% {
      transform: translateY(-8px);
      opacity: 0.2;
    }
  }

  @keyframes portal-inbox-app__list-item {
    0%,
    100% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.2, 1.2);
    }
  }
}
