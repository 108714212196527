@use '@angular/material' as mat;
@use "sass:map";

@mixin ee-messages-theme($theme) {
  $background: map.get($theme, background);
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);

  .general-message-wrapper blockquote.speech-bubble p {
    margin-bottom: 0 !important;
  }

  .speech-bubble {
    span.mention {
      border-radius: 0;
      background-color: transparent;
      -webkit-user-select: all;
      user-select: all;
      color: #000;
      font-weight: bolder;
      margin: 0;

      span {
        margin: 0;
      }
    }

    &.message--author span.mention {
      color: #fff;
    }
  }
}
