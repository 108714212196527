@use 'components/color-palette';
@use "sass:map";

@mixin ee-report-theme($theme, $typography) {
  $accent: map.get($theme, accent);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .ee-reports-wrapper {
    form.ee__tcv_left-column {
      overflow: auto;
      padding-bottom: 2rem;
    }

    .filters {
      padding: 16px;
      z-index: 1000;

      h3 {
        font-size: 1rem;
        font-weight: 500;
      }

      .field-list {
        max-height: 250px;
        overflow: auto;
      }

      .form-group {
        label {
          font-size: 1rem;
        }

        .field-list {
          border: 1px solid #cecece;
          padding: 10px;
          border-radius: 4px;
        }
      }

      .select-list {
        font-size: 0.75rem;

        .action {
          color: var(--primary-red);
          cursor: pointer;
        }
      }

    }

    .results {
      .table-wrapper {
        //margin: 16px;

        .result-header {
          min-width: 120px;
          text-align: center;

          .mat-sort-header-content {
            white-space: nowrap;
          }
        }

        .mat-sort-header-container {
          justify-content: center;
        }

        .value {
          text-align: center;
          padding-right: 12px;
          white-space: nowrap;

          &:last-of-type {
            padding-right: 36px;
          }

          span {
            padding: 0 2px;
          }
        }
      }

      .stats {
        width: 280px;
      }

      .export-button-wrapper {
        padding: 0 12px;
      }
    }

    .mat-mdc-paginator {
      padding-right: 4rem;
    }
  }
}
