@use '@angular/material' as mat;
@use 'utils';
@use "sass:map";

@mixin portal-dashboard-theme($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);

  // Subheader
  .portal-sidnav-menu__subheader {
    margin: 12px 0 0 0;
    padding: 0 16px;
    vertical-align: middle;
    text-transform: uppercase;
  }

  .semi-bold {
    font-weight: 600;
  }

  // Expansion Panel
  .mat-mdc-expansion-panel {
    box-shadow: none;

    .mat-mdc-expansion-panel-header {
      padding: 0px 16px;

      .mat-mdc-expansion-panel-header-title {
        margin: 0;
        align-items: center;

        h3 {
          margin: 0;
          padding: 0 16px;
        }

        .mat-icon {
          @include utils.portal-ui-icon-sizing(16px);
        }
      }
    }

    &.demo-mode {
      box-shadow: none;

      .mat-mdc-expansion-panel-header {
        display: none;
      }
      .mat-mdc-expansion-panel-content .mat-mdc-expansion-panel-body {
        padding: 0;
      }
    }
  }

  // Menu List.
  .mat-mdc-nav-list {
    padding: 0px;
    // Left menu icon
    .mat-mdc-list-icon {
      @include utils.portal-ui-icon-sizing(16px);
    }
  }

  .portal-sidnav-menu {
    overflow-y: auto;

    // Subheader
    .portal-sidnav-menu__subheader {
      margin: 12px 0 0 0;
      padding: 0 16px;
      vertical-align: middle;
      text-transform: uppercase;
    }

    .portal-sidnav-menu-spacer {
      height: 48px;
    }

    // Expansion Panel
    .mat-mdc-expansion-panel {
      box-shadow: none;

      .mat-mdc-expansion-panel-header {
        padding: 0px 16px;

        &.panel-header--link {
          cursor: pointer;
        }

        .mat-mdc-expansion-panel-header-title {
          margin: 0;
          align-items: center;
          color: #fff;

          h3 {
            margin: 0;
            padding: 0 16px;
          }

          .mat-icon {
            @include utils.portal-ui-icon-sizing(16px);
          }
        }
      }

      h3.portal-sidnav-menu__subheader {
        margin: 12px 0 0 0;
        padding: 0 16px 0 16px;
        background-color: inherit;
      }

      a.recent-eviction-item {
        height: 50px;

        .sub-header {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    // Menu List.
    .mat-mdc-nav-list {
      padding: 0px;
      // Left menu icon
      .mat-mdc-list-icon {
        @include utils.portal-ui-icon-sizing(16px);
      }

      span.eviction-status-indicator {
        text-align: right;
        font-size: 12px;
        text-transform: uppercase;
      }
    }

    // Stop home menu item from auto focusing when sidenav opens in over mode
    .mat-mdc-list-item-focus {
      background: transparent;
    }
  }

  .portal-dashboard-page-wrapper {
    background: mat.m2-get-color-from-palette($background, background);
  }

  .portal-widget--height-100 {
    height: 100%;
  }

  // Widget colors
  .portal-widget__color-primary {
    color: mat.m2-get-color-from-palette($primary, default);
  }
  .portal-widget__color-accent {
    color: mat.m2-get-color-from-palette($accent, default);
  }

  // Widget heading
  .portal-dashboard-page-wrapper .portal-widget__heading {
    border-color: mat.m2-get-color-from-palette($primary, default);

    &:after {
      background-color: mat.m2-get-color-from-palette($accent, default);
    }
  }
  [dir='rtl'] .portal-dashboard-page-wrapper .portal-widget__heading {
    padding-right: 12px;
    padding-left: 0;
    border-left-width: 0;
    border-right-width: 2px;
    border-right-style: solid;

    &:after {
      right: -2px;
      left: auto;
    }
  }

  // Widget content
  .portal-widget__content {
    .mat-mdc-card {
      box-sizing: border-box;
      height: 100%;
    }
  }
  .portal-widget__content--no-padding {
    .mat-mdc-card {
      padding: 0;
    }
  }
  .portal-widget__content--no-padding-bottom {
    .mat-mdc-card {
      padding-bottom: 0;
    }
  }

  // Widget content classes
  .portal-widget__padding {
    padding: 24px;
  }
  .portal-widget__padding-x {
    padding-left: 24px;
    padding-right: 24px;
  }
  .portal-widget__padding-y {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .portal-widget__content-divider {
    height: 1px;
    background: mat.m2-get-color-from-palette($foreground, dividers);
  }

  // Primary widget
  .portal-widget__content--primary {
    .mat-card {
      background: linear-gradient(0deg, mat.m2-get-color-from-palette($primary, default), mat.m2-get-color-from-palette($primary, 600));
      color: mat.m2-get-color-from-palette($primary, default-contrast);
    }

    .portal-widget__content-divider {
      background: rgba(mat.m2-get-color-from-palette($primary, default-contrast), 0.2);
    }
  }

  .ee-task-summary-icon {
    color: mat.m2-get-color-from-palette($accent, default);
  }
}
