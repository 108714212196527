@use '@angular/material' as mat;
@use "sass:map";

@mixin portal-menu-sidenav-theme($theme, $typography) {
  $accent: map.get($theme, accent);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .portal-sidnav-menu {
    background: mat.m2-get-color-from-palette($background, app-bar);
    color: mat.m2-get-color-from-palette($foreground, text);

    // Sub header
    .portal-sidnav-menu__subheader {
      font-size: mat.m2-font-size($typography, body-2);
      font-weight: mat.m2-font-weight($typography, body-2);
      line-height: mat.m2-line-height($typography, body-2);
      background: mat.m2-get-color-from-palette($background, app-bar);
    }

    // Expansion Panel
    .mat-expansion-panel {
      background: mat.m2-get-color-from-palette($background, app-bar);
    }

    .mat-expansion-panel-body {
      padding: 0px;
      background: mat.m2-get-color-from-palette($background, background);
      color: mat.m2-get-color-from-palette($foreground, text);
      box-shadow: 0px 3px 3px -1px rgba(0, 0, 0, 0.07) inset, 0px -3px 3px -1px rgba(0, 0, 0, 0.07) inset;

      .mat-mdc-list-item {
        opacity: 0;
        text-indent: 20px;
        transition-property: opacity, text-indent;
        transition-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
        transition-duration: 0.4s;
        /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
        .mat-list-item-content {
          padding: 0 16px;
        }
      }
    }

    // Submenu items animation
    .mat-expanded .mat-mdc-list-item {
      opacity: 1;
      text-indent: 0;
    }

    @for $i from 1 through 20 {
      .mat-expanded .mat-mdc-list-item:nth-child(#{$i}) {
        transition-delay: ($i - 1) * 0.12s;
      }
    }

    .mat-expansion-indicator {
      transition: transform 0.3s;
      width: 24px;
      text-align: center;

      &:after {
        padding: 2px;
      }
    }
  }
}
