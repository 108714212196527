@use '@angular/material' as mat;
@use "sass:map";
@use 'components/utils';

@mixin ee-registration-theme($theme) {
  $accent: map.get($theme, accent);
  .registration mat-icon {
    max-width: 24px;
    min-width: 24px;
    color: mat.m2-get-color-from-palette($accent, default);
  }
}
