@use '@angular/material' as mat;
@use "sass:map";

@mixin ee-layout-loader-theme($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .ee-layout-loader {
    background: mat.m2-get-color-from-palette($background, background);
    color: mat.m2-get-color-from-palette($foreground, text);
  }
}
