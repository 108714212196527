@use '@angular/material' as mat;
@use "sass:map";
@use 'components/utils';

@mixin ee-plan-theme($theme) {
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .subscription-plan {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex: 1 1 100%;

    .plan-header {
      background: mat.m2-get-color-from-palette($accent, default);
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &.current {
        background: mat.m2-get-color-from-palette($primary, default);
      }

      &.disabled {
        background: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 400);
      }
    }

    .plan-details {
      margin: 0;
      padding-bottom: 8px;
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
