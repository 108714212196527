@use '@angular/material' as mat;
@use '../responsive.mixin.scss';
@use 'color-palette';
@use 'utils';
@use "sass:map";

@mixin gridster($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);
  $pad: 15px;

  gridster.dashboard {
    background-color: #fff;
    overflow: auto;

    gridster-item {
      box-shadow: utils.$standard-shadow;
      padding: $pad;

      h3 {
        margin: 0 !important;
        font-weight: 700;
        font-size: 18px;

        &.padding-sides {
          padding-left: $pad;
          padding-right: $pad;
        }
      }

      &.no-shadow {
        box-shadow: none;
      }

      &.no-top-padding {
        padding-top: 0;
      }

      &.no-bottom-padding, &.pb-0 {
        padding-bottom: 0;
      }

      &.no-side-padding {
        padding-left: 0;
        padding-right: 0;
      }

      &.no-shadow {
        box-shadow: none;
        background: transparent;
        padding: 0;
      }

      .gridster-heading {
        padding: $pad $pad 0 $pad;
        text-align: left;
        margin: 0;
        font-weight: 400;
        font-size: 1rem;
      }
    }
  }

  gridster.dashboard-modern {
    background-color: #fff;
    overflow: auto;

    gridster-item {
      box-shadow: utils.$standard-shadow;
      padding: 2rem;

      h3 {
        margin: 0 !important;
        font-weight: 400;
        font-size: 1.1rem;

        &.padding-sides {
          padding-left: $pad;
          padding-right: $pad;
        }
      }

      &.no-top-padding {
        padding-top: 0;
      }

      &.no-bottom-padding {
        padding-bottom: 0;
      }

      &.no-side-padding {
        padding-left: 0;
        padding-right: 0;
      }

      &.no-shadow {
        box-shadow: none;
        background: transparent;
        padding: 0;
      }

      .gridster-heading {
        padding: $pad $pad 0 $pad;
        text-align: left;
        margin: 0;
        font-weight: 400;
        font-size: 1rem;
      }
    }
  }

  h1 {
    position: relative;
    flex: 0;
    font-size: 22px;
    padding: 0 20px;
    height: 50px;
    margin: 0;
  }

  .omnibar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    flex: 0 1 auto;
    width: 100%;
    /*overflow: hidden;*/
  }

  .toolbar {
    position: relative;
    padding-left: 10px;
  }

  .widgetbar {
    position: relative;
    padding: 10px 0;
    width: 275px;
  }

  .main-content {
    width: 100%;
    flex: 1 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .dashboard {
    flex: 1;
  }

  .panel-heading {
    border-bottom: 1px solid #F0F0F0;
    display: inline-block;
    position: relative;
    width: 100%;
    top: 0;
  }
  .panel-heading .panel-title {
    padding: 10px;
    margin: 0;
  }

  .panel-body {
    overflow: auto;
    padding: 0 10px 10px;
    position: absolute;
    top: 37px;
    bottom: 0;
    left: 0;
    right: 0;

    &.no-heading {
      top: 10px;
    }
  }

  .gridster-item-prototype {
    display: block;
    background-color: #afafaf;
    position: relative;
    float: left;
    z-index: 99;
    text-align: center;
    font-weight: bold;
    margin: 5px;
    width: 80px;
    height: 60px;

  }

  .gridster-item-prototype.is-over .gridster-item-inner {

    visibility: visible;
  }

  .gridster-item-prototype .gridster-item-inner {
    position: absolute;
    top:0;
    left: 0;
    overflow: auto;
    width: 80px;
    height: 60px;
    visibility: hidden;

    -webkit-transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
    -moz-transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
    -o-transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
    transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
  }

  .position-highlight .inner {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;

    background-color: #768294;
    border: 1px solid transparent;
    -webkit-transition: background 3s;
    transition: background 3s;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  .gridster-item-inner {
    position: absolute;
    background: #fff;
    border: 1px solid transparent;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    -webkit-transition: background 3s;
    transition: background 3s;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .is-dragging .gridster-item-inner {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.24), 0 2px 4px rgba(0, 0, 0, 0.48);
    top: -3px;
    bottom: 13px;
  }

}
