@use '@angular/material' as mat;
@use 'components/color-palette';
@use "sass:map";

@mixin portal-layout-compact-theme($theme, $typography) {
  $accent: map.get($theme, accent);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .portal-compact-sidemenu {
    background: mat.m2-get-color-from-palette($background, app-bar);
    color: mat.m2-get-color-from-palette($foreground, text);

    .portal-sidnav-menu {
      margin: 0;
      .nav-button.mdc-icon-button {
        font-size: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: 0;
        height: 60px;
        width: 100%;
        padding: 0;
        vertical-align: middle;

        &.nav-button-active, &:hover {
          background-color: var(--primary-color);
        }

        &.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
          left: auto;
          right: 1rem;
          top: auto;
          bottom: 1rem;
          z-index: 1000;
        }
      }
    }
  }

  .portal-compact-nested-sidenav {
    width: 275px;
    padding: 20px;
    line-height: 2rem;
    z-index: 100;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    background-color: var(--c-gray-99);
    border-right: 1px solid var(--c-gray-85);

    a {
      font-weight: 500;
      font-size: 1rem;
      text-decoration: none;
      color: var(--dark-gray);
      display: block;
      margin-bottom: 16px;

      &:hover {
        color: var(--primary-blue);
      }

      &.nested-sidenav-active {
        font-weight: 700;
        color: var(--primary-blue);
      }
    }

    a.pro.mat-badge, div.pro.mat-badge {
      .mat-badge-content {
        top: 65%;
      }
    }
  }

  .portal-compact-sidemenu, .portal-compact-nested-sidenav, .ee-user-menu-list, .pro-feature-card {
    .nav-button.mdc-icon-button.pro.mat-badge, a.pro.mat-badge, div.pro.mat-badge, h3.pro.mat-badge {
      .mat-badge-content {
        border-radius: 2px;
        height: 18px;
        line-height: 18px;
        width: 26px;
        font-weight: 600;
      }
    }

    button.mdc-icon-button {
      .mat-mdc-button-persistent-ripple {
        display: none;
      }
    }
  }

  .ee-user-menu-list a.pro.mat-badge .mat-badge-content {
    right: 10px;
    top: 18px;
  }
}

