@use '@angular/material' as mat;
@use 'components/utils';
@use 'components/color-palette';
@use "sass:map";

@mixin ee-workflow-editor-theme($theme) {
  $accent: map.get($theme, accent);

  form.workflow-step-edit-form {
    .none-added {
      padding: 10px;
    }

    .added-table {
      .added-item-row {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        margin-bottom: 8px;

        .mat-icon.delete {
          font-size: 18px;
          height: 18px;
          width: 18px;
          cursor: pointer;
        }
      }
    }

    .small.mat-mdc-select {
      border: 1px solid var(--c-gray-80);
      border-radius: 4px;
      padding-left: 6px;

      .mat-mdc-select-arrow-wrapper {
        width: 1rem;
      }
    }

    .sort-arrow {
      width: 14px;
      cursor: pointer;
      text-align: center;

      &:hover {
        background-color: var(--c-gray-98);
      }

      &.disabled {
        color: var(--c-gray-60);

        &:hover {
          background-color: #fff;
        }
      }
    }

    //button {
    //  border-radius: 4px;
    //  background-color: #fff;
    //  border-color: var(--c-gray-80);
    //  cursor: pointer;
    //
    //  &:focus {
    //    outline: none;
    //  }
    //}

    .custom-edit-section {
      border: 1px solid #000;
      border-radius: 4px;
      padding: 8px;

      .title {
        font-weight: 500;
      }

      .subtitle {
        font-size: 12px;
        line-height: 14px;
      }

      .field {
        padding: 4px 0;

        .label {
          font-size: 13px;
        }

        .mat-icon.delete {
          font-size: 18px;
          height: 18px;
          width: 18px;
          cursor: pointer;
        }

        input.error, select.error {
          border-color: mat.m2-get-color-from-palette($accent, default);
        }
      }

      .mat-icon.disabled {
        color: var(--c-gray-60);

        &:hover {
          background-color: #fff;
        }
      }

      .add-button {
        margin: 16px 6px 6px;
        border: 1px solid #000;
        font-weight: 500;
        padding: 2px 6px;
      }
    }
  }
}
