@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;
@use 'components/color-palette';
@use 'responsive.mixin.scss' as responsive;
@use 'app/invoice/invoice-styles';
@use '@fortawesome/fontawesome-pro/scss/fontawesome';
//@use 'ag-grid-enterprise/styles/_icon-font-codes';
@use "ag-grid-theme-builder.css";
@use 'components/theming' as theming;

@use 'components/fonts';
@use 'sass/portal' as portal;
/* Angular Material */
@use 'components/tagify' as tagify;

$fa-font-path: '@fortawesome/fontawesome-pro/webfonts';
@use '@fortawesome/fontawesome-pro/scss/regular';
@use '@fortawesome/fontawesome-pro/scss/brands';
@use '@fortawesome/fontawesome-pro/scss/solid';
@use '@fortawesome/fontawesome-pro/scss/light';

@import "mapbox-gl/dist/mapbox-gl.css";
@import "@ng-select/ng-select/themes/material.theme.css";
@import 'quill/dist/quill.snow.css';
@import 'quill/dist/quill.bubble.css';

@tailwind base;
@tailwind components;
@tailwind utilities;
@include mat.core();

// keep this to fix "outline" issue in mat-form-field + tailwindcss
*, ::before, ::after {
  border-style: none;
}

/* ==============================================================
Typography
===============================================================*/
$app-typography: mat.m2-define-typography-config(
  $font-family: 'Barlow, sans-serif',
  $headline-1: mat.m2-define-typography-level(112px, 112px, 300),
  $headline-2: mat.m2-define-typography-level(56px, 56px, 400),
  $headline-3: mat.m2-define-typography-level(45px, 48px, 400),
  $headline-4: mat.m2-define-typography-level(34px, 40px, 400),
  $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
  $headline-6: mat.m2-define-typography-level(20px, 32px, 500),
  $subtitle-1: mat.m2-define-typography-level(14px, 28px, 400),
  $subtitle-2: mat.m2-define-typography-level(14px, 24px, 600),
  $body-1: mat.m2-define-typography-level(14px, 24px, 400),
  $body-2: mat.m2-define-typography-level(14px, 24px, 400),
  $caption: mat.m2-define-typography-level(12px, 20px, 400),
  $button: mat.m2-define-typography-level(14px, 14px, 500)
);

$app-primary: mat.m2-define-palette(mat.$m2-gray-palette, A700, A100, A400);
$app-accent: mat.m2-define-palette(mat.$m2-red-palette, A700, A100, A400);
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$app-theme-colors: theming.portal-light-theme($app-primary, $app-accent, $app-warn);

$app-theme: mat.m2-define-light-theme((
  color: $app-theme-colors,
  typography: $app-typography,
  density: -2
));


// Create app styles
@include mat.all-component-themes($app-theme);
@include mat.all-component-densities(-2);
@include mat.all-component-typographies($app-typography);
@include mat.input-density(-5);
@include mat.checkbox-density(-3);
@include mat.menu-density(-4);
@include mat.autocomplete-density(-4);
@include mat.datepicker-density(-4);
@include mat.select-density(-5);
@include mat.button-density(0);
@include mtx.all-component-themes($app-theme);

@include portal.portal-theme($app-theme, $app-typography);
@include tagify.tagify($app-theme);

.mat-mdc-form-field-icon-prefix {
  margin-left: 8px;
}
.mat-mdc-form-field-infix {
  padding-top: 18px !important;
  padding-bottom: 6px !important;

  label.mdc-floating-label.mat-mdc-floating-label {
    display: block !important;
    top: 20px !important;
    margin-top: 4px;

    &.mdc-floating-label--float-above {
      padding-top: 0;
    }
  }
}

// Create the theme object (a Sass map containing all of the palettes).
$sidenav-theme: theming.portal-dark-theme($app-primary, $app-accent, $app-warn);

.portal-sidenav-theme {
  @include mat.all-component-colors($sidenav-theme);
  @include portal.portal-theme($sidenav-theme, $app-typography);
}

@mixin my-form-field-density($density) {
  $field-typography: mat.m2-define-typography-config(
    $body-1: mat.m2-define-typography-level(12px, 24px, 400),
  );
  @include mat.m2-typography-level($field-typography, 'body-1');
  @include mat.form-field-density($density);
  //margin-bottom: -18px;   //  leaving a bottom margin 8px
}

.compact {
  @include my-form-field-density(-5);

  &.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding-top: 6px !important;
    padding-bottom: 0px !important;
  }

  .mat-mdc-form-field-infix {
    padding-top: 14px !important;
    padding-bottom: 0 !important;

    label.mdc-floating-label.mat-mdc-floating-label {
      margin-top: 2px;
    }
  }

  .mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-hint {
    margin-top: -4px;
  }

  .mat-datepicker-toggle {
    button.mdc-icon-button.mat-mdc-icon-button {
      width: 32px;
      height: 32px;

      svg.mat-datepicker-toggle-default-icon {
        width: 18px;
        font-size: 18px;
        height: 18px;
      }
    }
  }

  .mat-mdc-form-field-icon-suffix {
    .mat-icon {
      padding: 0 8px 0 0;
    }
  }
}

html,
body {
  font-family: 'Barlow', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
}

.ee__standard-shadow {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.error {
  color: var(--primary-red);
}

.ee__link {
  color: var(--primary-red);
  cursor: pointer;
  text-decoration: underline;
}

.border-white {
  border-color: var(--c-white) !important;
}

.position-relative {
  position: relative;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.grid-row-bg-color {
  background-color: var(--c-gray-99);
}

.ng2-pdf-viewer-container {
  overflow-x: hidden !important;
}

.grid-row-bg-white {
  background-color: #fff;
}

.page-wrapper {
  padding: 16px;
  min-height: 100%;
  height: 100%;
}

.grid-page-wrapper {
  height: 100%;
  padding: 16px;
  margin: 0px;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
}

.overview-wrapper {
  padding: 16px;

  .no-padding-top {
    padding-top: 0;
  }
}

.bg-white {
  background-color: #fff;
}

.mat-mdc-checkbox {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-label {
    white-space: initial;
  }

  &.ng-invalid.ng-touched {
    color: #f44336;
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-frame {
      border-color: #f44336;
    }
  }
}

.scroll-y {
  overflow-y: auto;
  height: 100%;
}

.no-scroll-y {
  overflow-y: hidden;
}

.no-scroll-x {
  overflow-x: hidden;
}

.scroll-x {
  overflow-x: auto;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

// Widgets
.portal-widget {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.portal-widget__heading {
  text-transform: uppercase;
  padding-left: 12px;
  border-left-width: 2px;
  border-left-style: solid;
  position: relative;

  &:after {
    content: '';
    width: 2px;
    height: 0;
    position: absolute;
    bottom: 0;
    left: -2px;
    transition: height 0.5s;
  }

  &.secondary {
    border-color: var(--primary-red);
  }

  .portal-widget:hover &:after {
    height: 100%;
  }

  .action {
    text-transform: none;
    cursor: pointer;
  }
}

.attorney-logo {
  max-height: 50px;
}

.mat-icon {
  &.fa, &.far {
    line-height: 26px;
  }
}

// loading button
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-loading .mat-button-wrapper {
  visibility: hidden;
}

.mat-loading .mat-mdc-progress-spinner {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

// data-letters attribute css found here: https://stackoverflow.com/a/34310815/97817
[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  border-radius: 50%;
  background: #6F6F6F;
  vertical-align: middle;
  color: #fff;
}

[data-letters]:before {
  background-color: var(--user-selector-color);
}

.small[data-letters] {
  &:before {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: .75rem;
  }
}

.portal-widget.multi-box {
  padding: 2px;
  @include mat.elevation(5);
  margin: 2px;

  &.new-placeholder {
    border-radius: 4px;
    border: 5px dashed var(--c-gray-75);
    color: var(--c-gray-75);
    font-size: 30px;
    cursor: pointer;
    padding: 4px;
    text-align: center;

    mat-icon {
      margin-bottom: 20px;
    }
  }

  .mat-mdc-card.card {
    .icon {
      font-size: 30px;
    }

    .mat-mdc-card-content {
      &.hide-overflow {
        overflow-y: auto;
      }

      &.no-margin {
        margin: 0;
      }
    }

    table.details {
      padding: 0;
      font-size: 18px;
    }

    .mat-mdc-card-actions {
      text-align: right;
    }
  }
}

.card.floating-card {
  @media screen and (min-width: responsive.$break-md) {
    width: 750px;
  }

  @media screen and (max-width: responsive.$break-md - 1) {
    width: 100%;
  }
}

.floating-section {
  @media screen and (min-width: responsive.$break-md) {
    width: 750px;
  }

  @media screen and (max-width: responsive.$break-md - 1) {
    width: 100%;
  }
}

.floating-responsive-section {
  max-width: 900px !important;
  width: 100%;
}

router-outlet.router-flex + * {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

router-outlet.router-flex-full-height + * {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;

  &.scroll {
    overflow-y: auto;
  }
}

router-outlet.router-flex-full-height-shadow-padding + * {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 10px 0 10px 10px;
  margin: -10px;
}

.section-header {
  margin-top: 24px;
  font-weight: 500;
}

.full-width {
  width: 100%;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Generic styles
========================================================================== */

.instructions {
  opacity: 0.87;
  font-size: 16px;
}

.mat-step-header .mat-step-icon-selected {
  background-color: var(--primary-red);
}

/* ==========================================================================
entity tables helpers
========================================================================== */

/* Remove Bootstrap padding from the element
http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
@mixin no-padding($side) {
  @if $side == 'all' {
    .no-padding {
      padding: 0 !important;
    }
  } @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}

@include no-padding('left');
@include no-padding('right');
@include no-padding('top');
@include no-padding('bottom');
@include no-padding('all');

/* bootstrap 3 input-group 100% width
http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important;
}

/* Makes toolbar not wrap on smaller screens
http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details > {
  dd {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .row.jh-entity-details > {
    dt {
      margin-bottom: 15px;
    }

    dd {
      border-bottom: 1px solid #eee;
      padding-left: 180px;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mat-mdc-paginator.left-align {
  .mat-mdc-paginator-container {
    justify-content: flex-start;
  }
}

/* ==========================================================================
action bar interface
========================================================================== */
.action-bar {
  z-index: 1;
  padding: 16px 16px;
  height: 74px;

  &.compact {
    height: 50px;
  }
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.ap-input {
  height: 18px !important;
}

.mat-mdc-tooltip {
  background-color: rgba(97, 97, 97, .9);
}

.timepicker-overlay {
  z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}


.tree {
  font-size: 1rem;

  .node-content-wrapper, .tree-children {
    position: relative;
    margin-bottom: 10px;

    &:hover {
      border: none;
      background: none;
      box-shadow: none;
    }

    .node-label-wrapper {
      background-color: var(--primary-color);
      padding: 6px 10px;
      border-radius: 4px;
      color: #fff;
      opacity: .75;

      &.selected {
        font-weight: 600;
        opacity: 1;
      }
    }
  }

  .node-content-wrapper::before, .tree-children::after {
    content: "";
    position: absolute;
  }

  .node-content-wrapper::before {
    border-bottom: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    height: 28px;
    top: -17px;
    width: 20px;
    left: -28px;
  }

  .tree-node-level-1 > tree-node-wrapper > .node-wrapper > .node-content-wrapper::before {
    display: none;
  }

  .tree-node-leaf > .node-wrapper > .node-content-wrapper::before {
    width: 25px;
  }

  .tree-children::after {
    border-left: 1px solid lightgrey;
    height: 100%;
    top: -15px;
    left: -15px;
  }

  tree-node:last-child > .tree-node > .tree-children::after {
    border-left: none;
  }

  .toggle-children {
    z-index: 1;
  }
}

.plugin-error-message {
  color: var(--primary-red);
  font-size: .8rem;
}

ng-select {
  &.ng-invalid {
    &.ng-touched {
      .ng-select-container {
        border-color: #dc3545;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
      }
    }
  }
  .ng-select-container {
    background-color: var(--mdc-filled-text-field-container-color);
    padding-left: 1rem;
    padding-right: .75rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}
.ng-select {
  .ng-select-container {
    .ng-value-container {
      .ng-input {
        & > input {
          margin-top: 6px;
        }
      }
      .ng-placeholder {
        color: rgb(0, 0, 0, .6);
      }
    }
  }
  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          background-color: var(--dark-gray);
        }
      }
    }
  }
  &.ng-select-focused {
    .ng-select-container {
      &:after {
        border-color: var(--dark-gray);
        border-width: 1px;
      }
      .ng-value-container {
        .ng-placeholder {
          color: var(--dark-gray);
        }
      }
    }
  }
}

.connected {
  font-weight: 600;
  color: var(--primary-green);

  i {
    margin-right: 4px;
  }
}

ng-dropdown-panel.ng-select-multiple .ng-dropdown-panel-items .ng-option {
  &.ng-option-selected {
    color: #424242;
    input {
      accent-color: #424242;
    }
  }
}

html, body {
  height: 100%;
}

body {
  margin: 0;
}
