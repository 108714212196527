/* Portal Global  */
@use 'components/typography';
@use 'components/palette';
@use 'components/button';
@use 'components/theming';
@use 'components/menus';
@use 'components/backgrounds';
@use 'components/inputs';
@use 'components/toolbar';
@use 'components/tooltip';
@use 'components/scrollbars';
@use 'components/rtl';
@use 'components/theme-icon';
@use 'components/loader';
@use 'components/checkbox';
@use 'components/colors-theme';
@use 'components/typography-theme';
@use 'components/theme-dashboard';
@use 'components/inbox';
@use 'components/timeline';
@use 'components/chips';
@use 'components/datagrid';
@use 'components/stepper';
@use 'components/button-toggle-group';
@use 'components/tabs';
@use 'components/timepicker';
@use 'components/card';
@use 'components/fab';
@use 'components/list';
@use 'components/search';
@use 'components/dialog';
@use 'components/dropzone';
@use 'components/radio';
@use 'components/guided-tour';
@use 'components/gridster';
@use 'components/fonts';
@use 'components/three_column_view';
@use 'components/case-details';
@use 'components/utils';
@use 'components/section-styles';
@use 'components/toast';
@use 'components/quill';
@use 'components/ng-select';

/* Layout Components */
@use '../app/portal/components/user-menu/_user-menu-theme' as userMenu;
@use '../app/portal/components/notification-sidenav/_notification-sidenav-theme' as notificationSideNavTheme;
@use '../app/portal/components/menu-sidenav/_menu-sidenav-theme' as menuSidenavTheme;
@use '../app/portal/components/vertical-icon-menu/_vertical-icon-menu-theme' as verticalIconMenu;
@use '../app/portal/components/layout-loader/_layout-loader-theme.scss' as layoutLoaderTheme;
@use '../app/portal/components/top-horizontal-menu/_top-horizontal-menu-theme' as topHorizontalMenuTheme;
@use '../app/reports/_report-theme' as report-theme;
@use '../app/auth/register/registration-theme' as registration-theme;
@use '../app/dashboard/components/tabbed-chart-widget/_tabbed-chart-widget-theme' as tabbedChartWidgetTheme;
@use '../app/auth/auth-theme' as auth-theme;
@use '../app/eviction/details/breadcrumb-theme' as breadcrumb-theme;
@use '../app/eviction/details/case-details-theme' as case-details-theme;
@use '../app/billing/components/plan/plan-theme' as plan-theme;
@use '../app/messages/messages-theme' as messages-theme;
@use '../app/workflow/workflow-editor/workflow-editor-theme' as workflow-editor-theme;

@mixin portal-theme($theme, $typography) {

  /* Portal Typography */
  @include typography.portal-typography();

  @include loader.loader($theme);

  /* Menu Utility Classes */
  @include menus.portal-menus($theme);

  /* Input Classes */
  @include inputs.inputs();

  /* Toolbar Classes */
  @include toolbar.portal-toolbar($theme);

  /* Coloured background classes */
  @include backgrounds.portal-backgrounds($theme);

  /* Hide scrollbars classes and perfect scrollbar style */
  @include scrollbars.portal-scrollbars();

  /* RTL */
  @include rtl.portal-rtl();

  /* Portal Inbox List */
  @include inbox.portal-inbox($theme);

  /* Portal Timeline */
  @include timeline.portal-timeline($theme);

  /* Portal Stepper */
  @include stepper.portal-stepper($theme);

  /* Portal Button Toggle Group */
  @include button-toggle-group.portal-button-toggle-group($theme);

  /* Portal Layout Component Themes */
  @include userMenu.ee-user-menu-theme($theme);
  @include notificationSideNavTheme.portal-notification-sidenav($theme);
  @include menuSidenavTheme.portal-menu-sidenav-theme($theme, $typography);
  @include verticalIconMenu.portal-layout-compact-theme($theme, $typography);
  @include layoutLoaderTheme.ee-layout-loader-theme($theme);
  @include topHorizontalMenuTheme.ee-top-horizontal-menu-theme($theme);
  @include report-theme.ee-report-theme($theme, $typography);
  @include registration-theme.ee-registration-theme($theme);
  @include tabbedChartWidgetTheme.portal-tabbed-charts-widget-theme($theme);
  @include auth-theme.auth-theme($theme);
  @include breadcrumb-theme.breadcrumb-theme($theme);
  @include case-details-theme.case-details-theme($theme);
  @include plan-theme.ee-plan-theme($theme);
  @include messages-theme.ee-messages-theme($theme);
  @include workflow-editor-theme.ee-workflow-editor-theme($theme);

  /* Portal Dashboards */
  @include theme-dashboard.portal-dashboard-theme($theme);

  /* Error Pages Themes */
  //@include error-404-theme.portal-error-404-theme($theme);
  //@include error-500-theme.portal-error-500-theme($theme);

  /* Portal Typography Theme */
  @include typography-theme.portal-typography-theme($theme);

  /* UI Colors Page */
  //@include colors-theme.portal-colors-theme($theme);

  @include auth-theme.auth-theme($theme);
  @include breadcrumb-theme.breadcrumb-theme($theme);
  @include case-details-theme.case-details-theme($theme);
  @include chips.chips($theme);
  @include checkbox.checkbox($theme);

  @include datagrid.dataGrid($theme);
  @include tabs.tabs($theme);
  @include button.portal-button($theme);
  @include workflow-editor-theme.ee-workflow-editor-theme($theme);
  @include timepicker.timepicker($theme);
  @include card.card($theme);
  @include fab.fab($theme);
  @include list.list($theme);
  @include search.search($theme);
  @include dialog.dialog($theme);
  @include dropzone.dropzone($theme);
  @include radio.radio($theme);
  @include guided-tour.guidedTour($theme);
  @include gridster.gridster($theme);
  @include three_column_view.threeColumnView($theme);
  @include case-details.caseDetails($theme);
  @include tooltip.tooltip($theme);
  @include toast.toast($theme);
  @include quill.quill($theme);
  @include ng-select.ng-select($theme);

  .ee__red {
    color: var(--primary-red);
  }
}
