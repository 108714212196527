@use 'color-palette';

@mixin caseDetails($theme) {
  .ee__case-details {
    background-color: #F5F5F5;
    overflow: auto;

    .card {
      margin-bottom: 0 !important;
      box-shadow: none;
      background: transparent;
      border-bottom: 1px solid #D7D7D7;

      &:last-of-type {
        border-bottom: none;
      }

      .card-content {
        padding: 0 0 20px;
      }

      .card-header {
        padding: 0.5rem 0;
        h3 {
          font-size: 1rem;
          font-weight: 500;
        }

        button.ee__button {
          margin-right: 10px;
        }

        button.ee__chevron-button {
          color: #6F6F6F;
          margin-right: 0;

          .mat-icon {
            font-size: 1rem;
          }
        }

        button.ee__button.ee__eye-button {
          color: #6F6F6F;

          .mat-icon {
            font-size: 14px;
            width: 20px;
          }
        }
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}
