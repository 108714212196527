@use '@angular/material' as mat;
@use "sass:map";

@mixin portal-notification-sidenav($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .portal-notification-sidenav {
    background: mat.m2-get-color-from-palette($background, background);
    color: mat.m2-get-color-from-palette($foreground, text);
  }

  .portal-notifications-notification {
    background: mat.m2-get-color-from-palette($background, card);
    @include mat.elevation(1);

    .portal-notifications-subheader,
    .mat-mdc-card-header {
      background: mat.m2-get-color-from-palette($background, 'hover');
    }

    .mat-line mat-icon {
      color: mat.m2-get-color-from-palette($primary, A200);
    }
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
    .mat-card-header-text {
      flex: 1 1 0;
    }

    .mat-mdc-card-avatar {
      background: mat.m2-get-color-from-palette($primary, default);
      color: mat.m2-get-color-from-palette($primary, default-contrast);
    }

    &:hover:not(.expired) {
      @include mat.elevation(6);
    }
  }
  .portal-notifications-weather-item {
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    .mat-list-item-content div.mat-list-text {
      padding: 0;
    }
  }
}
