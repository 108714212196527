// styling borrowed from: https://github.com/CodyHouse/breadcrumbs-and-multistep-indicator/blob/master/css/style.css
@use 'components/color-palette';
@use 'responsive.mixin';
@use "sass:map";

@mixin case-details-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);

  .ee__tcv.ee_tcv_case_wrapper {
    display: flex;
    flex-direction: column;
    justify-items: stretch;
    align-items: stretch;
    width: 100%;
    height: 100%;
    overflow-y: hidden;

    .main-wrapper {
      display: flex;
      flex-direction: row;

      .action-center-buttons {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;
        justify-content: flex-end;
        align-items: center;

        button {
          margin-left: 10px;
        }
      }

      .ee__tcv_inner-right-column {
        min-width: 450px;
      }

      &.classic {
        flex-direction: row-reverse;

        .ee__tcv_inner-right-column {
          margin-left: 0;
          margin-right: 1.125rem;
        }

        .action-center-buttons {
          flex-direction: row-reverse;

          button {
            margin-left: 0;
            margin-right: 10px;
          }
        }
      }
    }
  }

}
