@use 'components/color-palette';

.invoice-details {
  .ee__invoice-detail-action {
    cursor: pointer;
    color: var(--primary-blue);
    border: 1px solid var(--c-gray-87);
    border-radius: 4px;
    padding: 2px 8px;
    font-weight: 500;
    min-width: 120px;
    text-align: center;
    font-size: 14px;
    height: 30px;
    display: block;
    line-height: 22px;

    &.disabled {
      color: var(--c-gray-87);
      border-color: var(--c-gray-87);
      cursor: default;

      &:hover {
        background-color: #fff;
        color: var(--c-gray-87);
        border-color: var(--c-gray-87);
      }
    }

    &.primary {
      color: #fff;
      background-color: var(--dark-gray);
      border: 1px solid var(--dark-gray);

      &:hover {
        color: #fff;
        background-color: var(--dark-gray);
      }
    }

    &.accent {
      color: #fff;
      background-color: var(--primary-red);
      border: 1px solid var(--primary-red);

      &:hover {
        color: #fff;
        background-color: var(--primary-red);
      }
    }

    &.plain {
      color: var(--dark-gray);
      background-color: #fff;
      border: 1px solid var(--dark-gray);

      &:hover {
        color: var(--dark-gray);
        background-color: var(--c-gray-99);
      }
    }

    &:hover {
      background-color: var(--primary-blue);
      color: white;
    }
  }

  .modern-invoice-view {
    padding: 1rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-items: stretch;
    color: #424242;
    font-size: 16px;

    .ee__modern-invoice-header {
      display: flex;
      flex-direction: column;
    }

    .ee__modern-invoice-label {
      font-size: 12px;
      color: #AEAEAE;
      font-weight: 500;
    }

    .ee__modern-invoice-number .large {
      font-size: 24px;
      font-weight: 600;
    }

    .mat-chip {
      font-size: 14px;
    }

    .ee_modern-invoice-table-wrapper {
      background-color: var(--light-gray-bg);
      font-weight: 600;
      padding: 1rem;
      overflow-x: auto;

      table.ee_modern-invoice-table {
        width: 100%;
        border-collapse: collapse;
        font-size: 14px;
        color: #424242;
        font-weight: 500;
        margin-bottom: 1rem;

        thead {
          font-size: 12px;
          font-weight: 500;
          background-color: #fff;
        }

        tr {
          td.type {
            width: 15%;
            text-align: left;
          }

          td.description {
            width: 55%;
            text-align: left;
          }

          td.rate, td.qty {
            width: 10%;
            text-align: center;
          }

          td.amount {
            width: 10%;
            text-align: right;
          }
        }

        tr {
          border-bottom: 1px solid #E0E0E0;
          background-color: #fff;
        }

        td {
          padding: 0.75rem 1.5rem;
        }

        tbody {
          font-size: 16px;

          @media screen and (max-width: var(--break-md) - 1) {
            font-size: 12px;
          }

          tr.ee_modern-invoice-table-section-header {
            font-weight: 600;
          }
        }
      }
    }

    .ee__modern-invoice-from {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      line-height: 1.75;
      color: #6F6F6F;
      margin-top: 1rem;
    }

    .ee_modern-invoice-table {
      background-color: var(--light-gray-bg);
      padding: 1rem;
    }
  }
}
