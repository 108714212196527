// styling borrowed from: https://github.com/CodyHouse/breadcrumbs-and-multistep-indicator/blob/master/css/style.css
@use 'components/color-palette';
@use 'responsive.mixin';
@use "sass:map";

@mixin breadcrumb-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);

  nav.breadcrumb {
    display: block;
    border: 1px solid #D7D7D7;
    margin-bottom: 30px;
    padding: 24px 30px 15px 30px;
  }

  @media screen and (max-width: var(--break-md)) {
    .status-breadcrumb li .details .completion-status {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  }

  .status-breadcrumb {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    li {
      transition: all 200ms ease-in;

      &:first-of-type .status {
        justify-content: flex-start;
        i {
          left: 0;
        }
      }

      &:last-of-type {
        width: 0 !important;
        .status {
          justify-content: flex-end;
          i {
            right: 0;
          }
          .details {
            text-align: right;
            overflow: visible;
            .step-name {
              text-align: right;
            }
            .completion-status {
              justify-content: flex-end;

              .status-summary {
                margin-right: 0;
              }
            }
          }
        }
      }

      i {
        position: absolute;
        top: -11px;
        font-size: 12px;
        width: 20px;
        height: 20px;
        color: #fff;
        background-color: #fff;
        border: 1px solid #AEAEAE;
        text-align: center;
        font-weight: 500;
        line-height: 19px;

        &.skipped {
          background-color: #AEAEAE;
        }

        &.fa.fa-check {
          background-color: var(--c-green);
          border-color: var(--c-green);
        }

        &.fa.fa-hourglass-start {
          background-color: var(--primary-red);
          border-color: var(--primary-red);
        }
      }

      .details {
        overflow: hidden;
        .step-name {
          line-height: 16px;
          text-align: left;
          color: #424242;
          font-weight: 600;
          padding-top: 14px;
          margin-bottom: 6px;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .completion-status {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          white-space: nowrap;

          .status-summary {
            background-color: #E0E0E0;
            color: var(--dark-gray);
            font-size: 10px;
            padding: 0 8px;
            margin-right: 10px;
            font-weight: 500;
            line-height: 18px;

            &.done {
              color: var(--c-green);
              background-color: var(--c-light-green);
            }

            &.current {
              color: var(--primary-red);
              background-color: var(--c-light-red);
            }

            &.pending {
              color: var(--c-blue);
              background-color: var(--c-light-blue);
            }
          }

          .completion-date {
            font-size: 12px;
            line-height: 12px;
          }
        }
      }

      .status {
        display: flex;
        justify-content: flex-start;
        border-top: 2px solid #AEAEAE;
        position: relative;
        transition: all 200ms ease-in;
      }

      .timestamp {
        display: table;
        align-items: center;
        font-weight: 100;
        width: 100%;
      }

      &.current {
        .status {
          border-top: 2px solid var(--primary-red);

          &:before {
            background-color: var(--primary-red);
            border: none;
            transition: all 200ms ease-in;
          }
        }
      }

      &.complete {
        .status {
          border-top: 2px solid var(--c-green);

          &:before {
            background-color: var(--c-green);
            border: none;
            transition: all 200ms ease-in;
          }
        }
      }

      &.skipped {
        .status {
          border-top: 2px solid var(--c-gray-50);

          &:before {
            background-color: var(--c-gray-50);
            border: none;
            transition: all 200ms ease-in;
          }
        }
      }
    }
  }
}
