@use 'components/color-palette';

@mixin auth-theme($theme) {
  .auth-container-v2 {
    .auth-section {
      background-color: #fff;

      .logo-wrapper {
        text-align: center;
        .logo-link {
          .logo {
            width: 50%;
          }
        }
      }

      .header {
        font-size: 1.25rem;
        text-align: center;
      }

      form {
        margin: 1.5rem 6rem;
      }
    }

    .auth-details {
      background-color: var(--dark-gray);
      .hero {
        width: 50%;
      }
    }

    a.small-link {
      font-size: 12px;
      text-align: center;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .auth-container {
    min-height: 100%;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;

    .website-logo {
      position: fixed;
      top: 60px;

      img {
        width: 300px;
      }
    }

    .auth-card {
      padding: 0;

      .logo-link {
        text-align: center;
        margin-bottom: 2rem;

        img.logo {
          width: 90%;
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
      mat-card-content {
        min-height: 350px;
        padding: 48px;
        margin: 0;
      }

      .mat-mdc-form-field {
        width: 100%;
      }
    }

    a.alert-link {
      font-weight: bold;
      color: var(--primary-red);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    a.small-link {
      font-size: 12px;
      text-align: center;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .footer {
      font-size: 14px;
      padding: 10px;

      a {
        cursor: pointer;
        text-decoration: underline;

        &.switch-login {
          font-weight: 400;
        }
      }
    }
  }

  // IE 11 fixes
  @media all and (-ms-high-contrast: none) {
    .auth-container {
      height: 100%;
    }
  }
}
