@use 'color-palette';
@use '../responsive.mixin' as responsive;

@mixin threeColumnView($theme) {
  .ee__tcv {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    align-items: stretch;
    max-height: 100%;

    .ee__tcv_left-column, .ee__tcv_inner-right-column {
      width: 380px;
      min-width: 380px;
      overflow: hidden;
      display: flex;
      flex-direction: column !important;
      justify-content: start;
      align-items: stretch;
      padding: 1.250rem 1.125rem 0;
      background-color: #F5F5F5;

      &.no-bg {
        background-color: transparent;
      }
    }

    .ee__tcv_left-column {
      margin-top: 1.125rem;
      margin-left: 1.125rem;
      margin-bottom: 0;

      &.m-0 {
        margin: 0;
      }

      .filter-section {
        button.mdc-icon-button.filter-btn {
          color: var(--primary-red);
          border: 1px solid #9E9E9E;
          background-color: #fff;
          border-radius: 4px;
          max-height: 36px;
          max-width: 36px;

          .mat-icon {
            margin: 0;
            line-height: 16px;
            height: 20px;
            width: 18px;
            font-size: 14px;
          }

          &:hover {
            background-color: #F5F5F5;

            .mat-mdc-button-persistent-ripple {
              display: none;
            }
          }
        }

        &.separator {
          border-bottom: 1px solid #ccc;
          padding-bottom: 1rem;
          margin-bottom: 1rem;
        }
      }

      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .mat-mdc-list .mat-mdc-list-item.ee__tcv_active, .mat-mdc-list .mat-mdc-list-item:hover {
        .mat-mdc-list-item-line > span, .mat-mdc-list-item-line > div, .mat-mdc-list-item-line {
          color: #000;
        }
      }

      .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
        border-right: none;
      }

      .mat-mdc-form-field-infix {
        padding-top: 8px !important;
        font-size: 13px;
      }

      mat-form-field {
        background-color: #fff;
      }
    }

    .ee__tcv_middle-column {
      padding: 1.125rem;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;
      flex: 1;

      &.no-padding {
        padding: 0;
      }

      .ee__readonly {
        background-color: #F5F5F5;
        padding: 20px;
        display: flex;
        flex-direction: column;

        .ee__readonly-header {
          flex-direction: column;
          display: flex;

          .ee_readonly-top-row {
            font-size: 1.5rem;
            margin-bottom: 10px;
          }

          .ee__readonly-subheader {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 10px;

            i {
              margin-right: 10px;
              width: 20px;
              text-align: center;
            }
          }
        }

        .ee__readonly-main {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: stretch;

          @media screen and (min-width: responsive.$break-md) {
            flex-direction: row;
          }
        }

        //.ee__daily-docket-header {
        //  .title {
        //    font-size: 24px;
        //    font-weight: 500;
        //  }
        //}

        .left-side-wrapper, .right-side-wrapper {
          display: flex;
          flex-direction: column;
          flex: 1;
        }

        .left-side-wrapper {
          padding-right: 20px;
        }

        .right-side-wrapper {
          .claims {
            display: flex;
            flex-direction: column;
            background-color: #fff;
            padding: 20px;
          }
        }

      }

      //.ee__tcv_inner-right-column {
      //  @media screen and (min-width: responsive.$break-md) {
      //    //width: 750px;
      //  }
      //
      //  @media screen and (max-width: responsive.$break-lg - 1) {
      //    display: none;
      //    position: absolute;
      //    z-index: 99;
      //    background: #FFF;
      //    right: 0;
      //    height: 100%;
      //    padding-left: 10px;
      //    padding-bottom: 80px;
      //    border-left: 1px solid #d7d7d7;
      //    margin-top: -17px;
      //  }
      //}

      &.p-0 {
        padding: 0;
      }

      .ee__tcv_inner-right-column {
        margin-left: 1.125rem;

        &.shadow-friendly {
          margin-left: -1.125rem;
          padding-left: 2.5rem;
          margin-top: -1rem;
          padding-top: 1rem;
          margin-right: -1rem;
          padding-right: 1rem;
        }

        &.ee__case-details {
          padding: 0;
        }
      }

      ag-grid-angular {
        width: 100%;
        height: 100%;
      }
    }

    .field-list-wrapper {
      font-size: 12px;

      label {
        font-size: 1rem;
        font-weight: 500;
      }

      label.mdc-label {
        font-size: inherit;
        font-weight: inherit;
      }

      .action {
        cursor: pointer;
      }

      .field-list {
        border: 1px solid #cecece;
        background-color: #fff;
        padding: 4px;
        border-radius: 4px;
        max-height: 250px;
        overflow: auto;

        label {
          font-size: .875rem;
          font-weight: 400;
        }
      }
    }

    .ee__tcv_label-blue, .ee__tcv_label-gray, .ee__tcv_label-orange {
      padding: 4px 10px;
      font-weight: 600;
      opacity: 1;
    }

    .ee__tcv_label-blue {
      background: rgba(70, 87, 187, 0.15);
      color: var(--primary-blue);
      opacity: 1;
    }

    .ee__tcv_label-gray {
      background: #E0E0E0;
      color: #424242;
      opacity: 1;
    }

    .ee__tcv_label-orange {
      background: rgba(213, 96, 0, 0.25);
      color: #424242;
      opacity: 1;
    }
  }
}
