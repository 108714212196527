@use '@angular/material' as mat;
@use 'components/utils' as utils;
@use "sass:map";

@mixin ee-user-menu-theme($theme) {
  $accent: map.get($theme, accent);

  // Menu header
  .mat-mdc-menu-item.ee-user-menu-header {
    padding: 14px;
    background: #4657BB;
    opacity: 1 !important;

    .mat-mdc-menu-item-text {
      display: flex;
    }

    .mdc-list-item__primary-text {
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: mat.m2-get-contrast-color-from-palette($accent, 600) !important;
    }

    .ee-user-menu-header__details {
      color: #fff;
      h2,
      h3 {
        margin: 0px;
      }
    }

    .ee-user-menu-header__avatar {
      border-radius: 50%;
      margin: auto 16px auto 0;
      flex-shrink: 1;
      font-size: 1.25rem;
      width: 50px;
      height: 50px;
    }
  }


  // Button
  button.mat-mdc-button.ee-user-menu-button {
    height: 48px;

    .ee-user-menu-button__avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-left: 10px;
    }

    .ee-user-menu-button__username {
      margin: 0 4px;
      font-size: 16px;
      color: #616161;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .ee-user-menu-list {
    padding-top: 0px;
    min-width: 230px;

    .mat-icon {
      @include utils.portal-ui-icon-sizing(16px);
    }

    button {
      background: transparent;
      height: 56px;

      .mat-icon {
        margin-right: 0;
        color: #000;
      }
    }

    .label {
      margin-left: 16px;
    }
  }
}
